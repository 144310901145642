import { message, Modal } from "antd";
import { innovitiPaymentCodes } from "../../../../constants/config";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import * as Sentry from "@sentry/react";
import { getOAuthHeaders } from "../../../../constants/oAuthValidation";

export const paymentProvider = (
  amountAdded,
  currentPaymentMethod,
  paymentMethod,
  value,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  completePayment,
  setSelectedPaymentMethod,
  setAmount,
  setPaytmQrCodeModalOpens,
  setQrCodeResponse,
  setShowCardPaymnteModal,
  setCardPaymnetError,
  setCardPaymnetStatus
) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  if (currentPaymentMethod.paymentProvider === "INV" && currentPaymentMethod.integratedPayment === true) {
    processInnovitiEDCPayment(
      amountAdded,
      currentPaymentMethod,
      paymentMethod,
      value,
      tillData,
      tillLayout,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      completePayment,
      setSelectedPaymentMethod,
      setAmount
    );
  } else if (currentPaymentMethod.paymentProvider === "PTM" && currentPaymentMethod.integratedPayment === true) {
    processPaytmEDCPayment(
      amountAdded,
      currentPaymentMethod,
      paymentMethod,
      value,
      tillData,
      tillLayout,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      completePayment,
      setSelectedPaymentMethod,
      setAmount
    );
  } else if (currentPaymentMethod.paymentProvider === "ADC" && currentPaymentMethod.integratedPayment === true) {
    processADCPayment(
      amountAdded,
      currentPaymentMethod,
      paymentMethod,
      value,
      tillData,
      tillLayout,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      completePayment,
      setSelectedPaymentMethod,
      setAmount,
      setShowCardPaymnteModal,
      setCardPaymnetError,
      setCardPaymnetStatus
    );
  } else if (currentPaymentMethod.integratedPayment === false && currentPaymentMethod.paymentProvider === "PTM") {
    processQrPaytmPayment(
      amountAdded,
      currentPaymentMethod,
      paymentMethod,
      value,
      tillData,
      tillLayout,
      cart,
      setCart,
      setLoader,
      setPaymentModal,
      completePayment,
      setSelectedPaymentMethod,
      setAmount,
      setPaytmQrCodeModalOpens,
      setQrCodeResponse
    );
  } else if (currentPaymentMethod.integratedPayment === true) {
    message.error("Invalid payment provider configuration");
  }
};

const processInnovitiEDCPayment = (
  amountAdded,
  currentPaymentMethod,
  paymentMethod,
  value,
  tillData,
  tillLayout,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  completePayment,
  setSelectedPaymentMethod,
  setAmount
) => {
  const edcUrl = process.env.REACT_APP_edcUrl;
  if (amountAdded >= 1) {
    const requestCode = innovitiPaymentCodes[currentPaymentMethod.name];
    const trxId = uuidv4().replace(/-/g, "").toUpperCase();
    const cashierId = tillData.tillAccess.csUserId;
    const customerMobileNo = cart.customer.mobileNo;
    setLoader(true);
    setPaymentModal(false);
    console.info(`Innoviti Request URL: ${edcUrl}?value=0,${requestCode},${trxId}!${cashierId}!${customerMobileNo}!,${amountAdded.toString().split(".").join("")}`);
    Axios.get(`${edcUrl}?value=0,${requestCode},${trxId}!${cashierId}!${customerMobileNo}!,${amountAdded.toString().split(".").join("")}`)
      .then((response) => {
        const result = response.data;
        const { ResponseCode, ResponseMessage } = result;
        if (ResponseCode === "00") {
          setLoader(false);
          if (tillLayout === 2) {
            setPaymentModal(true);
          }
          message.success("Payment Success");
          completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, 0);
        } else {
          setLoader(false);
          if (tillLayout === 2) {
            setPaymentModal(true);
          }
          message.error(`Payment Failed: ${ResponseMessage}`);
        }
      })
      .catch((error) => {
        console.error("Payment Failed:", error);
        setLoader(false);
        if (tillLayout === 2) {
          setPaymentModal(true);
        }
        message.error("Payment Failed: Transaction timeout / Check EDC Connection");
      });
  } else {
    console.warn("Minimum amount not satisfied");
  }
};

const cancelPaytmVerifyPaymentRequest = (options) => {
  options.data.type = 6;
  Axios(options);
};

const paytmVerifyEDCPaymentStatus = (options) => {
  return new Promise(async function (verify) {
    Modal.confirm({
      title: "Payment request initiated",
      content: "Please verify",
      okText: "Verify",
      onOk: () => {
        verify("retry");
      },
      onCancel: () => {
        cancelPaytmVerifyPaymentRequest(options);
        verify("cancel");
      },
    });
  });
};

const chekPaytmEDCPaymentStatus = (options) => {
  return new Promise(async function (process, reject) {
    let retries = true;
    while (retries) {
      try {
        const response = await Axios(options);
        const result = response.data;
        // const result = { "result": 0, "resultCode": "101", "resultMessage": "Transaction already present", "transactionID": null, "authorizationID": null, "request": null, "properties": null };
        if (result.result?.toString() === "200") {
          retries = false;
          process(true);
        } else if (result.result?.toString() === "202" || result.result?.toString() === "203") {
          message.info(result.resultMessage);
          const retryStatus = await paytmVerifyEDCPaymentStatus(options);
          if (retryStatus === "retry") {
            retries = true;
          } else {
            message.error("Payment canceled");
            retries = false;
            process(false);
          }
        } else {
          message.error(result.resultMessage);
          retries = false;
          process(false);
        }
      } catch (err) {
        retries = false;
        reject(err);
      }
    }
  });
};

const processPaytmEDCPayment = (
  amountAdded,
  currentPaymentMethod,
  paymentMethod,
  value,
  tillData,
  tillLayout,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  completePayment,
  setSelectedPaymentMethod,
  setAmount
) => {
  const trxId = uuidv4().replace(/-/g, "").toUpperCase();
  setLoader(true);
  if (tillLayout === 2) {
    setPaymentModal(true);
  }
  const requestData = {
    url: `${tillData.tillAccess.cwrTill.hardwareController.imageUrl}payment`,
    method: "POST",
    data: {
      type: 0,
      transactionID: trxId,
      terminalID: "111",
      currency: "INR",
      amount: amountAdded,
      properties: {},
      paymentProvider: "Paytm",
    },
    headers: {
      "Content-Type": "Application/json",
    },
  };

  chekPaytmEDCPaymentStatus(requestData)
    .then((response) => {
      if (response) {
        setLoader(false);
        if (tillLayout === 2) {
          setPaymentModal(true);
        }
        message.success("Payment Success");
        completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, 0);
      } else {
        setLoader(false);
        if (tillLayout === 2) {
          setPaymentModal(true);
        }
      }
    })
    .catch((error) => {
      console.error("Payment Failed:", error);
      setLoader(false);
      if (tillLayout === 2) {
        setPaymentModal(true);
      }
      message.error("Payment Failed: Transaction timeout / Check EDC Connection");
    });
};

const processQrPaytmPayment = async (amountAdded, currentPaymentMethod, paymentMethod, value, tillData, cart, setPaytmQrCodeModalOpens, setQrCodeResponse) => {
  let setAuthTokens;
  const authHeaders = await getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    setAuthTokens = authHeaders.access_token;
  }
  setPaytmQrCodeModalOpens(true);
  let hostUrl = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let paytmUrl = `${hostUrl}paytm/generateQRCode`;
  const getQRCodeValues = {
    midId: "Excelo34085435005810",
    orderId: `${cart.sOrderID}`,
    amount: amountAdded,
    businessType: "UPI_QR_CODE",
    posId: `${tillData.tillAccess.cwrTill.cwrTillID}`,
    merchantKey: "qQUxrwRx@qE6zTxt",
    payTMQRUrl: "https://securegw-stage.paytm.in/paymentservices/qr/create",
    clientId: "C11",
    version: "v1",
  };
  Axios({
    url: paytmUrl,
    method: "POST",
    data: getQRCodeValues,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${setAuthTokens}`,
    },
  }).then((response) => {
    setQrCodeResponse(response.data);
  });
};

const processADCPayment = async (
  amountAdded,
  currentPaymentMethod,
  paymentMethod,
  value,
  tillData,
  tillLayout,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  completePayment,
  setSelectedPaymentMethod,
  setAmount,
  setShowCardPaymnteModal,
  setCardPaymnetError,
  setCardPaymnetStatus
) => {
  let setAuthTokens;
  const authHeaders = await getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    setAuthTokens = authHeaders.access_token;
  }
  const adcUrl = `${tillData.tillAccess?.cwrTill?.pHWController?.imageUrl}payment`;
  const tillValue = JSON.parse(localStorage.getItem("tillValue"));
  const nameTill = tillValue.name;
  const adcValues = {
    type: 0,
    terminal: nameTill,
    transaction: `${tillData.tillAccess.cwrTill.prefix}${cart.documentno}`,
    ReceiptNo: `${tillData.tillAccess.cwrTill.prefix}${cart.documentno}`,
    amount: amountAdded - cart.paid,
    currency: tillData.tillAccess.csBunit.currencies[0].isoCode,
    properties: {},
    test: true,
  };

  const controller = new AbortController();
  const signal = controller.signal;

  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 90000);
  setCardPaymnetStatus({
    paymentStatus: true,
    message: "Payment is processing, Please wait",
  });
  try {
    const response = await Axios.post(adcUrl, adcValues, {
      headers: {
        "Content-Type": "application/xml; charset=UTF-8",
        Accept: "application/xml",
      },
      signal,
    });
    clearTimeout(timeoutId);
    if (response.data.resultCode === "00" || response.data.resultCode === "0000") {
      setShowCardPaymnteModal(false);
      cart.cardPaymentData.payload = [adcValues];
      cart.cardPaymentData.response = [response.data];
      completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, 0);
    } else {
      setCardPaymnetError(true);
      setCardPaymnetStatus({
        paymentStatus: false,
        message: response.data.resultMessage || "Please check the hardware controller is not responding",
      });
      handleCardPaymentError(response, adcUrl, adcValues, tillData);
    }
  } catch (error) {
    clearTimeout(timeoutId);
    const errorMessage = error?.response?.data?.message || error?.message || "Please check the hardware controller is not responding";
    setCardPaymnetStatus({
      paymentStatus: false,
      message: errorMessage,
    });
    Sentry.captureException(error);
    setCardPaymnetError(true);
    console.error("Error occurred during card payment:", errorMessage);
  }
};

const handleCardPaymentError = async (response, adcUrl, adcValues, tillData) => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const authHeaders = await getOAuthHeaders();
  let cleanToken;
  if (authHeaders) {
    cleanToken = authHeaders.access_token;
  }
  const additionalData = {
    tillValue: JSON.parse(localStorage.getItem("tillValue")),
    adcUrl: adcUrl,
    adcValues: adcValues,
  };
  const exceptionData = {
    CardPaymentError: {
      response: response.data,
      requestData: response?.data?.request,
      ...additionalData,
    },
  };
  const uniqueId = uuidv4().replace(/-/g, "").toUpperCase();
  const workFlowResponse = await Axios({
    url: serverUrl,
    method: "POST",
    data: {
      query: `mutation {
        upsertWorkflowEventStore(eventStore: [{
             aggregatorId:  "${uniqueId}"
             aggregatorType: "POS"
             eventType: "CE"
             eventData: "${JSON.stringify(exceptionData).replace(/"/g, '\\"')}"
          }]) {
          status
          message
        }
      } `,
    },
    headers: {
      "Content-Type": "Application/json",
      Authorization: `${cleanToken}`,
    },
  });
  Sentry.captureException(new Error("Card payment failed"), {
    extra: exceptionData,
  });
};
