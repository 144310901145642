import { getOAuthHeaders } from "../../../../constants/oAuthValidation";
import { message } from "antd";
import Axios from "axios";
import { paymentProvider } from "./paymentProvider";
import { redeemLoyality } from "./loyality";
import i18next from "i18next";
import moment from "moment";
import upsertPOSLog from "../../Retail/posLog";

export const addAmount = async (
  paymentMethod,
  value,
  grant,
  cart,
  setCart,
  setLoader,
  setPaymentModal,
  setAmount,
  setLoyalityOtpData,
  setLoyalityOtpModalVisible,
  setSelectedPaymentMethod,
  setPaytmQrCodeModalOpens,
  setQrCodeResponse,
  overPayedAmount,
  setShowCardPaymnteModal,
  setCardPaymnetError,
  setCardPaymnetStatus
) => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  const newPaymentAmount = parseFloat(value).toFixed(2);
  const t = i18next.t;
  let setAuthTokens;
  let amountAdded;
  const authHeaders = await getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    setAuthTokens = authHeaders.access_token;
  }
  if (cart.payments.length > 0) {
    const currentPaymentsTotal = cart.payments
      .reduce((total, payment) => {
        const paymentAmount = parseFloat(payment.amount);
        return isNaN(paymentAmount) ? total : total + paymentAmount;
      }, 0)
      .toFixed(2);
    amountAdded = (parseFloat(currentPaymentsTotal) + parseFloat(newPaymentAmount)).toFixed(2);
  } else {
    amountAdded = parseFloat(value).toFixed(2);
  }
  if (grant !== undefined) {
    completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, overPayedAmount);
  } else {
    const validation = RegExp("^[0-9]+(.[0-9]{1,2})?$");
    if (paymentMethod !== undefined && paymentMethod !== "" && validation.test(parseFloat(value)) && value > 0) {
      const currentPaymentMethod = paymentMethod;
      if (currentPaymentMethod.integratedPayment || currentPaymentMethod.integratedPayment === false) {
        paymentProvider(
          amountAdded,
          currentPaymentMethod,
          paymentMethod,
          value,
          cart,
          setCart,
          setLoader,
          setPaymentModal,
          completePayment,
          setSelectedPaymentMethod,
          setAmount,
          setPaytmQrCodeModalOpens,
          setQrCodeResponse,
          setShowCardPaymnteModal,
          setCardPaymnetError,
          setCardPaymnetStatus
        );
      }
      if (currentPaymentMethod.iscredit) {
        if (cart.customer.iscredit && value <= cart.total - cart.paid) {
          setLoader(true);
          setPaymentModal(false);
          Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query{
                verifyCredit(customerId:"${cart.customer.cwrCustomerId}"){
                    iscredit
                    creditLimit
                }
            }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${setAuthTokens}`,
            },
          })
            .then((creditResponse) => {
              const { iscredit, creditLimit } = creditResponse.data.data.verifyCredit;
              if (iscredit) {
                if (amountAdded > 0 && amountAdded <= creditLimit) {
                  {
                    message.success(`${t("credit_payment_successfull")}`);
                  }
                  completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, overPayedAmount);
                } else {
                  {
                    message.warning(`${t("credit_payment_less_amount_error")}`);
                  }
                }
              } else {
                message.warning("Customer not eligible for credit payment !");
              }
            })
            .catch((err) => {
              console.error(err);
              message.error("Payment Failed !");
            })
            .finally(() => {
              setLoader(false);
              if (tillLayout === 2) {
                setPaymentModal(true);
              }
            });
        } else {
          {
            message.warning(`${t("credit payment is can not be more than sale amount")}`);
          }
        }
      } else if (currentPaymentMethod.isloyalty) {
        setLoader(true);
        redeemLoyality(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, setAuthTokens, completePayment);
      } else if (currentPaymentMethod.isGiftCard) {
        completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, overPayedAmount);
      } else if (currentPaymentMethod.name.toLowerCase() === "cash") {
        completePayment(paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, overPayedAmount);
      } else if (
        (currentPaymentMethod.integratedPayment === false && currentPaymentMethod.paymentProvider === "ADC") ||
        (currentPaymentMethod.integratedPayment === false && currentPaymentMethod.paymentProvider === null)
      ) {
        setShowCardPaymnteModal(true);
        setCardPaymnetError(true);
        setCardPaymnetStatus({
          paymentStatus: false,
          message: "",
        });
      } else if (currentPaymentMethod?.name?.toLowerCase() === "cash") {
        cart.tenderedAmount = +value + (+cart.tenderedAmount || 0);
      }
    }
  }
};

export const completePayment = (paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, overPayedAmount) => {
  const paymentAddons = cart.payments;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const amountAdded = parseFloat(value);
  const currentPaymentMethod = paymentMethod;
  let shouldRemoveRedeemPoints = false;

  if (paymentMethod.isloyalty && amountAdded < 0) {
    shouldRemoveRedeemPoints = true;
  }

  const index = paymentAddons.findIndex((p) => p.finPaymentmethodId === currentPaymentMethod.finPaymentmethodId);
  if (index >= 0) {
    const newAmount = parseFloat(paymentAddons[index].amount) + parseFloat(amountAdded);
    let paidAmount = cart.paid + parseFloat(amountAdded);
    paymentAddons[index].amount = parseFloat(newAmount).toFixed(2);
    if (paidAmount - Math.abs(cart.total) > 0) {
      paymentAddons[index].amount = paymentAddons[index].amount - (paidAmount - Math.abs(cart.total));
      cart.change = paidAmount - Math.abs(cart.total + parseFloat(overPayedAmount));
    }
  } else {
    if (amountAdded !== 0) {
      let paidAmount = cart.paid + parseFloat(amountAdded);
      currentPaymentMethod.amount = parseFloat(amountAdded).toFixed(2);
      if (paidAmount - Math.abs(cart.total) > 0) {
        currentPaymentMethod.amount = parseFloat(amountAdded).toFixed(2) - (paidAmount - Math.abs(cart.total));
        cart.change = paidAmount - Math.abs(cart.total + parseFloat(overPayedAmount));
      }
      paymentAddons.push(currentPaymentMethod);
    }
  }

  let totalAmountAdded = 0;
  const paymentMethodsCount = paymentAddons.length;
  for (let i = 0; i < paymentMethodsCount; i += 1) {
    totalAmountAdded += parseFloat(paymentAddons[i].amount);
  }

  if (paymentMethod.redemptionPoints) {
    cart.redemptionPoints = paymentMethod.redemptionPoints;
  }

  const redemptionPts = shouldRemoveRedeemPoints ? 0 : cart.redemptionPoints;

  let creditAmountValue = cart.creditAmount;
  if (paymentMethod.iscredit && amountAdded > 0) {
    if (cart.creditAmount > 0) {
      creditAmountValue = cart.creditAmount + amountAdded;
    } else {
      creditAmountValue = amountAdded;
    }
  }

  if (paymentMethod.iscredit && amountAdded < 0) {
    creditAmountValue = 0;
  }
  cart.total = parseFloat(cart.total.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
  const finalCartObj = {
    ...cart,
    paid: parseFloat(totalAmountAdded.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
    payments: [...paymentAddons],
    redemptionPoints: redemptionPts,
    creditAmount: creditAmountValue,
  };

  let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
    ? JSON.parse(localStorage.getItem("orderTimeDetails"))
    : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
  if (orderTimeDetails.paymentStartTime === "") {
    orderTimeDetails = {
      ...orderTimeDetails,
      paymentStartTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // Update orderStartTime to current time
    };
  }

  localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
  setCart(finalCartObj);
  localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
  setSelectedPaymentMethod("");
  setAmount(
    Math.abs(cart.total) > Math.abs(totalAmountAdded)
      ? parseFloat(Math.abs(cart.total) - Math.abs(totalAmountAdded)).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)
      : ""
  );
  upsertPOSLog(finalCartObj, "PAY");
};
